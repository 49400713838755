<template>
  <div>
    <h2 v-html="$t('search_processing_grouping_title')"></h2>
    <!-- Gesuchsteller einzeln -->
    <v-switch
      id="search_processing_grouping_dairy"
      v-model="searchCardStore.groupByDairy"
      color="primary"
      :label="$t('search_processing_grouping_dairy')"
      @keyup.enter="search"
    ></v-switch>
    <!-- Datum einzeln -->
    <v-switch
      id="search_processing_grouping_date"
      v-model="searchCardStore.groupByDate"
      color="primary"
      :label="$t('search_processing_grouping_date')"
      @keyup.enter="search"
    ></v-switch>
    <!-- Blöcke einzeln -->
    <v-switch
      id="search_processing_grouping_block"
      v-model="searchCardStore.groupByBlock"
      color="primary"
      :label="$t('search_processing_grouping_block')"
      @keyup.enter="search"
    ></v-switch>
    <!-- Label einzeln -->
    <v-switch
      id="search_processing_grouping_label"
      v-model="searchCardStore.groupByLabel"
      color="primary"
      :label="$t('search_processing_grouping_label')"
      @keyup.enter="search"
    ></v-switch>
  </div>
</template>

<script lang="ts">
import baseSearchVue from './baseSearch.vue'
import { useSearchProcessingGroupingStore } from '@/store/SearchProcessingGroupingStore'

import { defineComponent } from 'vue'
export default defineComponent({
  name: 'searchProcessingGrouping',
  extends: baseSearchVue,
  beforeMount() {
    this.setSearchCardStore(useSearchProcessingGroupingStore(), true)
  }
})
</script>
