import { defineStore } from 'pinia'
import { TermItemDefinition } from '@/services/term'

export const useSearchProcessingGroupingStore = defineStore('searchProcessingGroupingStore', {
  state: () => ({
    groupByDairy: null as string | null,
    groupByDate: null as string | null,
    groupByBlock: null as string | null,
    groupByLabel: null as string | null,
    items: [
      { key: 'groupByDairy', operation: ':', itemType: 'boolean' },
      { key: 'groupByDate', operation: ':', itemType: 'boolean' },
      { key: 'groupByBlock', operation: ':', itemType: 'boolean' },
      { key: 'groupByLabel', operation: ':', itemType: 'boolean' },
      { key: 'LocalUnit_egid', operation: ':' }
    ] as TermItemDefinition[]
  })
})
